import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button } from "src/templates"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FraudIllustration from "src/assets/svg/prevenir-fraude.svg";

// Imports para los contenidos

export default function PrevencionDeFraudes() {

    const theme = useTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'))

    const styles = {
        preventFraudBackground: {
            background: `${theme.palette.primary.main}20`,
            padding: !lg ? "180px 0px 60px" : "240px 0px 60px",
           },
           preventFraudContainer: {
            display: 'flex',
            alignItems: 'center',
            padding: !lg ? "0px 10% !important" : "auto",
            flexDirection: !lg ? 'row' : 'column',
            rowGap: !lg ? 20 : 5,
            columnGap: 5,
           },
    }

    return (
        <Box sx={styles.preventFraudBackground}>
        <Box sx={styles.preventFraudContainer}>
            <Box sx= {{width: !lg ? "50%" : "90%"}}>
                <Typography variant="h1" color= {"primary.main"} sx={{ pb: 6 }} textAlign={lg ? "center" : "left"}>
                    Prevención de fraudes
                </Typography>
                <Typography variant="subtitle1" color= {"primary.dark"} sx={{ pb: 4 }}>
                    Estamos en conocimiento de que han existido casos de intentos de fraude mediante suplantación de identidad a empresas financieras.
                </Typography>
                <Typography variant="subtitle1" color= {"primary.dark"} sx={{ pb: 2 }}>
                    Queremos darte la seguridad de que trabajar con nosotros es 100% seguro. Por eso, te contamos algunas maneras de reconocer a Cumplo y sus representantes comerciales:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="subtitle1" color= {"primary.dark"} sx={{ pb: 2 }}>
                            En Cumplo trabajamos con personas jurídicas, facilitando el financiamiento a empresas. No entregamos créditos a personas físicas.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="subtitle1" color= {"primary.dark"} sx={{ pb: 2 }}>
                            No solicitamos dinero por anticipado para solicitar ninguno de nuestros productos.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="subtitle1" color= {"primary.dark"} sx={{ pb: 2 }}>
                            Tampoco trabajamos con intermediarios ni otras marcas.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="subtitle1" color= {"primary.dark"} sx={{ pb: 2 }}>
                            Todos nuestros contactos oficiales tienen correos electrónicos con el dominio cumplo.com o cumplo.mx
                        </Typography>
                    </li>
                </ul>
                <Typography variant="subtitle1" color= {"primary.dark"} sx={{ pb: 6 }}>
                    {`Si recibes algún mensaje que use la marca Cumplo y crees que pueda ser fraudulento, por favor infórmanos al correo electrónico `}
                        <a
                            style={{ color: theme.palette.secondary.main, textDecoration: "none" }}
                            href={"mailto:contacto@cumplo.com"}
                            rel="noopener noreferrer"
                        >
                            contacto@cumplo.com
                        </a>
                    {` para sumarlo a nuestra investigación y causa judicial.`}
                </Typography>
                <Box sx={{ textAlign: lg ? "center" : "left" }}>
                    <a href="/" style={{ textDecoration: 'none'}}>
                        <Button variant="contained">Volver al inicio</Button>
                    </a>
                </Box>
            </Box>
            {!lg && <Box width="50%" component="img" src={FraudIllustration} /> }
        </Box>
        </Box>
    )
 
}